import React, { useEffect, useState } from "react";
import PortfolioItem from "./PortfolioItem";
import { useLocation } from "react-router-dom";

import casino_1_DH from "../../assets/images/Portfolio/casino-1-DH.jpg";
import casino_2_DH from "../../assets/images/Portfolio/casino-2-DH.png";
import casino_3_DH from "../../assets/images/Portfolio/casino-3-DH.png";
import casino_4_DH from "../../assets/images/Portfolio/casino-4-DH.png";
import casino_1_AB from "../../assets/images/Portfolio/casino-1-AB.png";
import casino_2_AB from "../../assets/images/Portfolio/casino-2-AB.png";
import casino_3_AB from "../../assets/images/Portfolio/casino-3-AB.png";
import casino_4_AB from "../../assets/images/Portfolio/casino-4-AB.png";
import casino_5_DH from "../../assets/images/Portfolio/casino-5-DH.png";
import casino_6_DH from "../../assets/images/Portfolio/casino-6-DH.jpg";
import casino_6_AB from "../../assets/images/Portfolio/casino-6-AB.png";
import casino_7_AB from "../../assets/images/Portfolio/casino-7-AB.png";
import casino_hotel_1_DH from "../../assets/images/Portfolio/casino-hotel-1-DH.jpg";
import casino_hotel_2_DH from "../../assets/images/Portfolio/casino-hotel-2-DH.png";
import casino_hotel_1_NG from "../../assets/images/Portfolio/casino-hotel-1-NG.png";
import casino_hotel_1_NC from "../../assets/images/Portfolio/casino-hotel-2-NC.png";
import casino_hotel_2_NC from "../../assets/images/Portfolio/casino-hotel-3-NC.png";
import casino_hotel_3_NC from "../../assets/images/Portfolio/casino-hotel-1-NC.png";
import casino_hotel_4_NG from "../../assets/images/Portfolio/casino-hotel-4-NG.png";
import casino_hotel_2_NG from "../../assets/images/Portfolio/casino-hotel-2-NG.png";
import casino_hotel_3_NG from "../../assets/images/Portfolio/casino-hotel-3-NG.png";
import e_commerce_1_DH from "../../assets/images/Portfolio/e-commerce-1-DH.png";
import e_commerce_2_DH from "../../assets/images/Portfolio/e-commerce-2-DH.png";
import e_commerce_3_DH from "../../assets/images/Portfolio/e-commerce-3-DH.png";
import e_commerce_4_DH from "../../assets/images/Portfolio/e-commerce-4-DH.png";
import e_commerce_5_DH from "../../assets/images/Portfolio/e-commerce-5-DH.png";
import e_commerce_6_DH from "../../assets/images/Portfolio/e-commerce-6-DH.png";
import e_commerce_7_DH from "../../assets/images/Portfolio/e-commerce-7-DH.jpg";
import e_commerce_8_DH from "../../assets/images/Portfolio/e-commerce-8-DH.jpg";
import e_commerce_9_DH from "../../assets/images/Portfolio/e-commerce-9-DH.jpg";
import fantasy_1_DH from "../../assets/images/Portfolio/fantasy-1-DH.png";
import fantasy_1_AB from "../../assets/images/Portfolio/fantasy-1-AB.png";
import fantasy_2_AB from "../../assets/images/Portfolio/fantasy-2-AB.png";
import fantasy_3_AB from "../../assets/images/Portfolio/fantasy-3-AB.png";
import fantasy_4_AB from "../../assets/images/Portfolio/fantasy-4-AB.png";
import fantasy_2_DH from "../../assets/images/Portfolio/fantasy-2-DH.png";
import fantasy_3_DH from "../../assets/images/Portfolio/fantasy-3-DH.png";
import fantasy_4_DH from "../../assets/images/Portfolio/fantasy-4-DH.png";
import fantasy_5_AB from "../../assets/images/Portfolio/fantasy-5-AB.png";

const portfolioItems = [
  {
    imageUrl: fantasy_1_DH,
    category: "Fantasy Sports",
    mainCategory: "Gambling",
  },
  {
    imageUrl: casino_1_DH,
    category: "Social Casino",
    mainCategory: "Gambling",
  },
  {
    imageUrl: casino_2_DH,
    category: "Social Casino",
    mainCategory: "Gambling",
  },
  {
    imageUrl: casino_3_DH,
    category: "Social Casino",
    mainCategory: "Gambling",
  },
  {
    imageUrl: casino_4_DH,
    category: "Social Casino",
    mainCategory: "Gambling",
  },
  {
    imageUrl: casino_1_AB,
    category: "Social Casino",
    mainCategory: "Gambling",
  },
  {
    imageUrl: casino_2_AB,
    category: "Social Casino",
    mainCategory: "Gambling",
  },
  {
    imageUrl: casino_3_AB,
    category: "Social Casino",
    mainCategory: "Gambling",
  },
  {
    imageUrl: casino_4_AB,
    category: "Social Casino",
    mainCategory: "Gambling",
  },
  {
    imageUrl: casino_5_DH,
    category: "Social Casino",
    mainCategory: "Gambling",
  },
  {
    imageUrl: casino_6_DH,
    category: "Social Casino",
    mainCategory: "Gambling",
  },
  {
    imageUrl: casino_6_AB,
    category: "Social Casino",
    mainCategory: "Gambling",
  },
  {
    imageUrl: casino_7_AB,
    category: "Social Casino",
    mainCategory: "Gambling",
  },
  {
    imageUrl: casino_hotel_1_NG,
    category: "Casino Hotels",
    mainCategory: "Gambling",
  },
  {
    imageUrl: casino_hotel_1_DH,
    category: "Casino Hotels",
    mainCategory: "Gambling",
  },
  {
    imageUrl: casino_hotel_2_DH,
    category: "Casino Hotels",
    mainCategory: "Gambling",
  },
  {
    imageUrl: casino_hotel_1_NC,
    category: "Casino Hotels",
    mainCategory: "Gambling",
  },
  {
    imageUrl: casino_hotel_2_NC,
    category: "Casino Hotels",
    mainCategory: "Gambling",
  },
  {
    imageUrl: casino_hotel_3_NC,
    category: "Casino Hotels",
    mainCategory: "Gambling",
  },
  {
    imageUrl: casino_hotel_2_NG,
    category: "Casino Hotels",
    mainCategory: "Gambling",
  },
  {
    imageUrl: casino_hotel_3_NG,
    category: "Casino Hotels",
    mainCategory: "Gambling",
  },
  {
    imageUrl: casino_hotel_4_NG,
    category: "Casino Hotels",
    mainCategory: "Gambling",
  },
  {
    imageUrl: e_commerce_1_DH,
    category: "E-Commerce",
    mainCategory: "Gambling",
  },
  {
    imageUrl: e_commerce_2_DH,
    category: "E-Commerce",
    mainCategory: "Gambling",
  },
  {
    imageUrl: e_commerce_3_DH,
    category: "E-Commerce",
    mainCategory: "Gambling",
  },
  {
    imageUrl: e_commerce_4_DH,
    category: "E-Commerce",
    mainCategory: "Gambling",
  },
  {
    imageUrl: e_commerce_5_DH,
    category: "E-Commerce",
    mainCategory: "Gambling",
  },
  {
    imageUrl: e_commerce_6_DH,
    category: "E-Commerce",
    mainCategory: "Gambling",
  },
  {
    imageUrl: e_commerce_7_DH,
    category: "E-Commerce",
    mainCategory: "Gambling",
  },
  {
    imageUrl: e_commerce_8_DH,
    category: "E-Commerce",
    mainCategory: "Gambling",
  },
  {
    imageUrl: e_commerce_9_DH,
    category: "E-Commerce",
    mainCategory: "Gambling",
  },
  {
    imageUrl: fantasy_1_AB,
    category: "Fantasy Sports",
    mainCategory: "Gambling",
  },
  {
    imageUrl: fantasy_2_AB,
    category: "Fantasy Sports",
    mainCategory: "Gambling",
  },
  {
    imageUrl: fantasy_3_AB,
    category: "Fantasy Sports",
    mainCategory: "Gambling",
  },
  {
    imageUrl: fantasy_4_AB,
    category: "Fantasy Sports",
    mainCategory: "Gambling",
  },
  {
    imageUrl: fantasy_2_DH,
    category: "Fantasy Sports",
    mainCategory: "Gambling",
  },
  {
    imageUrl: fantasy_3_DH,
    category: "Fantasy Sports",
    mainCategory: "Gambling",
  },
  {
    imageUrl: fantasy_4_DH,
    category: "Fantasy Sports",
    mainCategory: "Gambling",
  },
  {
    imageUrl: fantasy_5_AB,
    category: "Fantasy Sports",
    mainCategory: "Gambling",
  },
];

const mainCategories = ["Gambling"];
const subcategories = {
  Gambling: ["Social Casino", "Casino Hotels", "Fantasy Sports", "E-Commerce"],
};

const Portfolio = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [selectedMainCategory, setSelectedMainCategory] = useState("Gambling");
  const [selectedSubCategory, setSelectedSubCategory] = useState(null);

  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const handlePrevClick = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? filteredItems.length - 1 : prevIndex - 1
    );
  };

  const handleNextClick = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === filteredItems.length - 1 ? 0 : prevIndex + 1
    );
  };

  const handleThumbnailClick = (index) => {
    setCurrentIndex(index);
  };

  const handleMainCategoryChange = (category) => {
    setSelectedMainCategory(category);
    setSelectedSubCategory(null); // Reset subcategory
    setCurrentIndex(0); // Reset index to the first element
  };

  const handleSubCategoryChange = (category) => {
    setSelectedSubCategory(category);
    setCurrentIndex(0); // Reset index to the first element
  };

  // Filtered items should be an empty array if no main category or subcategory is selected
  const filteredItems = portfolioItems.filter((item) => {
    if (!selectedMainCategory || !selectedSubCategory) return false;
    return (
      item.mainCategory === selectedMainCategory &&
      item.category === selectedSubCategory
    );
  });

  return (
    <section className="portfolio">
      {/* Main Category Tabs */}
      <div className="portfolio__main-categories">
        {mainCategories.map((category) => (
          <button
            key={category}
            className={`portfolio__main-category ${
              selectedMainCategory === category ? "active" : ""
            }`}
            onClick={() => handleMainCategoryChange(category)}
          >
            {category}
          </button>
        ))}
      </div>

      {/* Subcategories if a main category is selected */}
      {selectedMainCategory && (
        <div className="portfolio__sub-categories">
          {subcategories[selectedMainCategory].map((subcategory) => (
            <button
              key={subcategory}
              className={`portfolio__sub-category ${
                selectedSubCategory === subcategory ? "active" : ""
              }`}
              onClick={() => handleSubCategoryChange(subcategory)}
            >
              {subcategory}
            </button>
          ))}
        </div>
      )}

      {/* Intro Block */}
      <div className="portfolio__intro">
        <h2 className="portfolio__title">Our Portfolio</h2>
        <p className="portfolio__description">
          Explore our collection of landing pages and advertising campaigns that
          we have crafted for various clients. Each project is designed to
          achieve maximum impact and deliver measurable results. For
          confidentiality reasons, all names have been replaced with "DOMAIN" or
          placeholders.
        </p>
        <p className="portfolio__note">
          *Please note that animations and hover effects are not displayed in
          these examples.
        </p>
      </div>

      {/* Check if a subcategory is selected and filteredItems is not empty */}
      {selectedSubCategory && filteredItems.length > 0 && (
        <>
          {/* Thumbnails */}
          <div className="portfolio__thumbnails-wrapper">
            <div className="portfolio__thumbnails">
              {filteredItems.map((item, index) => (
                <img
                  key={index}
                  src={item.imageUrl}
                  alt={`Thumbnail ${index + 1}`}
                  className={`portfolio__thumbnail ${
                    currentIndex === index ? "active" : ""
                  }`}
                  onClick={() => handleThumbnailClick(index)}
                />
              ))}
            </div>
          </div>

          {/* Controls for next/previous */}
          <div className="portfolio__controls">
            <button
              onClick={handlePrevClick}
              className="portfolio__button portfolio__button--prev"
            >
              Previous
            </button>
            <span className="portfolio__counter">
              {currentIndex + 1} / {filteredItems.length}
            </span>
            <button
              onClick={handleNextClick}
              className="portfolio__button portfolio__button--next"
            >
              Next
            </button>
          </div>

          {/* Show the selected portfolio item */}
          <div className="portfolio__content">
            <PortfolioItem imageUrl={filteredItems[currentIndex].imageUrl} />
          </div>
        </>
      )}

      {/* Show a message if no items are available */}
      {selectedSubCategory && filteredItems.length === 0 && (
        <p>No items available for this category.</p>
      )}
    </section>
  );
};

export default Portfolio;
