import React from "react";

const PortfolioItem = ({ title, description, imageUrl }) => {
  return (
    <div className="portfolio-item">
      <div className="portfolio-item__image">
        <img src={imageUrl} alt={title} />
      </div>
    </div>
  );
};

export default PortfolioItem;
