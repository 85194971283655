import React from "react";

const WhatWeOffer = () => {
  return (
    <section className="what-we-offer">
      <div className="what-we-offer__overlay"></div>
      <div className="what-we-offer__content">
        <h2 className="what-we-offer__title">What We Offer</h2>
        <p className="what-we-offer__description">
          At Fromolded Solutions, we specialize in crafting comprehensive
          digital solutions tailored to meet your business objectives. Our
          services span from creating visually stunning landing pages to
          developing complex web applications that drive results.
        </p>
        <div className="what-we-offer__services">
          <div className="what-we-offer__service">
            <h3 className="what-we-offer__service-title">Landing Pages</h3>
            <p className="what-we-offer__service-description">
              We create engaging landing pages designed to convert visitors into
              customers, using the latest design trends and conversion
              techniques.
            </p>
          </div>
          <div className="what-we-offer__service">
            <h3 className="what-we-offer__service-title">Advertising Pages</h3>
            <p className="what-we-offer__service-description">
              Our advertising pages are optimized for performance, ensuring your
              campaigns deliver the best possible return on investment.
            </p>
          </div>
          <div className="what-we-offer__service">
            <h3 className="what-we-offer__service-title">Web Applications</h3>
            <p className="what-we-offer__service-description">
              From simple tools to complex platforms, we develop web
              applications that are reliable, scalable, and aligned with your
              business goals.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhatWeOffer;
