import React, { useEffect } from "react";
import {
  FaRocket,
  FaMobileAlt,
  FaSearch,
  FaBolt,
  FaPenFancy,
  FaImage,
  FaCheckCircle,
  FaCogs,
  FaLightbulb,
} from "react-icons/fa";
import { Link, useLocation } from "react-router-dom";

const servicesData = [
  {
    icon: <FaRocket />,
    title: "Custom Websites",
    description:
      "We create custom websites from scratch tailored to your brand and business needs, ensuring a unique online presence.",
  },
  {
    icon: <FaMobileAlt />,
    title: "Responsive Design",
    description:
      "Our websites are designed to be fully responsive, providing an optimal experience on all devices, from desktops to smartphones.",
  },
  {
    icon: <FaSearch />,
    title: "SEO Optimization",
    description:
      "We optimize your website for search engines to improve your rankings, drive more traffic, and increase conversions.",
  },
  {
    icon: <FaBolt />,
    title: "Speed Optimization",
    description:
      "We enhance your website's loading speed to provide a faster user experience, reduce bounce rates, and improve SEO performance.",
  },
  {
    icon: <FaPenFancy />,
    title: "Copywriting",
    description:
      "Our expert copywriters create compelling and SEO-friendly content that engages your audience and drives conversions.",
  },
  {
    icon: <FaImage />,
    title: "Image Generation",
    description:
      "We generate high-quality images and graphics that enhance the visual appeal of your website and support your brand identity.",
  },
  {
    icon: <FaCheckCircle />,
    title: "Website Reviews",
    description:
      "We provide detailed reviews of your existing website, offering actionable insights to improve user experience and performance.",
  },
  {
    icon: <FaCogs />,
    title: "Maintenance",
    description:
      "We offer ongoing website maintenance services to keep your site running smoothly, secure, and up-to-date with the latest technologies.",
  },
  {
    icon: <FaLightbulb />,
    title: "UX/UI Design",
    description:
      "We craft intuitive and aesthetically pleasing user interfaces that enhance user experience and drive engagement. ",
  },
];

const Services = () => {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <section className="services">
      <div className="services__intro">
        <h2 className="services__title">Our Services</h2>
        <p className="services__description">
          We offer a comprehensive range of services designed to meet your
          digital marketing needs, from creating stunning websites to optimizing
          your online presence. Explore our services below:
        </p>{" "}
        <br />
        <Link to="/portfolio" className="services__button">
          Learn More
        </Link>
      </div>
      <div className="services__grid">
        {servicesData.map((service, index) => (
          <div key={index} className="service">
            <div className="service__icon">{service.icon}</div>
            <h3 className="service__title">{service.title}</h3>
            <p className="service__description">{service.description}</p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Services;
