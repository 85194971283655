import React from "react";
import { FaTelegramPlane, FaEnvelope } from "react-icons/fa"; // Importing icons
import MainLogo from "../assets/images/Fromolded.svg";
import { Link } from "react-router-dom"; // Assuming you're using react-router for navigation

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer__top">
        <div className="footer__brand-container">
          <img src={MainLogo} alt="Fromolded Logo" className="footer__logo" />
          <h2 className="footer__brand">Fromolded</h2>
        </div>
        <div className="footer__contacts">
          <h3 className="footer__contacts-title">Contacts</h3>
          <a href="https://t.me/fromolded" className="footer__contact-link">
            <FaTelegramPlane /> Telegram
          </a>
          <a href="mailto:fromolded@gmail.com" className="footer__contact-link">
            <FaEnvelope /> fromolded@gmail.com
          </a>
        </div>
        <nav className="footer__nav">
          <a href="../#hero-banner" className="footer__nav__link">
            Home
          </a>
          <Link to="/contact" className="footer__nav__link">
            Contact Us
          </Link>
          <Link to="/portfolio" className="footer__nav__link">
            Portfolio
          </Link>
        </nav>
      </div>
      <div className="footer__bottom">
        <p>© 2024 Fromolded. All Rights Reserved.</p>
        <div className="footer__links">
          <Link to="/terms" className="footer__link">
            Terms & Conditions
          </Link>
          <Link to="/privacy" className="footer__link">
            Privacy & Policy
          </Link>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
