import React from "react";
import { Link } from "react-router-dom";

const servicesData = [
  {
    id: 1,
    title: "Facebook Ads",
    description:
      "Unlock the power of social engagement with our Facebook Ads services. We create and manage targeted ad campaigns to boost your brand's presence, drive website traffic, and increase conversions. From ad design to audience analysis, we've got you covered.",
    features: [
      "Optimized for Conversion",
      "Ad Creation & Optimization",
      "Customizable Call-to-Actions",
      "Responsive & Modern Design",
    ],
    image: "facebook-ads.webp",
    buttonText: "Transform visitors into customers!",
  },
  {
    id: 2,
    title: "Google Ads",
    description:
      "Create visually captivating landing pages designed to engage visitors and drive conversions. At Fromolded Solutions, we blend modern design trends with strategic conversion techniques, ensuring your landing page delivers impactful results.",
    features: [
      "Keyword Research & Strategy",
      "Custom Application Development",
      "Landing Page Optimization",
      "Ongoing Support & Maintenance",
    ],
    image: "google-ads.webp",
    buttonText: "Maximize visibility in searches!",
  },
];

const Offers = () => {
  return (
    <section>
      <div className="services-container">
        <h2 className="services_title" data-text="Fromolded Solutions">
          We Work With
        </h2>
        <div className="services-grid">
          {servicesData.map((service) => (
            <div key={service.id} className="service-card">
              <img
                src={require(`../../assets/images/${service.image}`)}
                alt={service.title}
                className="service-image"
              />
              <h2 className="service-title">{service.title}</h2>
              <p className="service-description">{service.description}</p>
              {/* <ul className="service-features">
            {service.features.map((feature, index) => (
              <li key={index}>{feature}</li>
            ))}
          </ul> */}
              <Link to="/contact" className="service-button">
                {service.buttonText}
              </Link>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Offers;
