import React from "react";
import { Link } from "react-router-dom";
import MainLogo from "../assets/images/Fromolded.svg";

const Header = () => {
  return (
    <header className="header">
      <Link className="header__logo" to="/">
        <img className="header__logo__img" src={MainLogo} alt="Logo" />
        <p className="header__logo__text">Fromolded</p>
      </Link>
      <nav className="header__nav">
        <a href="../#hero-banner" className="header__nav__link">
          Home
        </a>
        <Link to="/contact" className="header__nav__link">
          Contact Us
        </Link>
        <Link to="/portfolio" className="header__nav__link">
          Portfolio
        </Link>
      </nav>
    </header>
  );
};

export default Header;
