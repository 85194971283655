import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

const CentralSection = () => {
  const location = useLocation();

  // Прокрутка наверх при изменении маршрута
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  // Функция для скроллинга к секциям
  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <section className="central-section">
      {/* Стрелка вверх для перехода на секцию наверх */}
      <div
        className="central-section__arrow central-section__arrow--up"
        onClick={() => scrollToSection("hero-banner")}
      >
        <span className="central-section__arrow-text">About Us</span>↑
      </div>

      <div className="central-section__content">
        {/* Стрелка влево для перехода на другую страницу */}
        <Link
          to="/contact"
          className="central-section__arrow central-section__arrow--left"
        >
          ←<span className="central-section__arrow-text">Contact Us</span>
        </Link>

        {/* Название компании с эффектом глитч */}
        <div className="central-section__title">
          <h1 className="glitch" data-text="Fromolded Solutions">
            Fromolded Solutions
          </h1>
        </div>

        {/* Стрелка вправо для перехода на другую страницу */}
        <Link
          to="/portfolio"
          className="central-section__arrow central-section__arrow--right"
        >
          →<span className="central-section__arrow-text">Portfolio</span>
        </Link>
      </div>

      {/* Стрелка вниз для перехода на секцию вниз */}
      <div
        className="central-section__arrow central-section__arrow--down"
        onClick={() => scrollToSection("why-us")}
      >
        ↓<span className="central-section__arrow-text">Why Us</span>
      </div>
    </section>
  );
};

export default CentralSection;
