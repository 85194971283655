import React from "react";

const PrivacyPolicy = () => {
  return (
    <div className="privacy">
      <h1 className="privacy__title">Privacy & Policy</h1>
      <section className="privacy__section">
        <h2 className="privacy__subtitle">Introduction</h2>
        <p className="privacy__text">
          At Fromolded, your privacy is important to us. This Privacy & Policy
          explains how we collect, use, and protect your personal information
          when you use our services. By using our website, you agree to the
          collection and use of information in accordance with this policy.
        </p>
      </section>

      <section className="privacy__section">
        <h2 className="privacy__subtitle">Information We Collect</h2>
        <p className="privacy__text">
          We collect personal information that you provide directly to us, such
          as when you fill out forms on our website or contact us via email or
          Telegram. This may include your name, email address, and any other
          details you choose to share with us.
        </p>
      </section>

      <section className="privacy__section">
        <h2 className="privacy__subtitle">How We Use Your Information</h2>
        <p className="privacy__text">
          The personal information we collect is used to:
        </p>
        <ul className="privacy__list">
          <li>Provide and maintain our services</li>
          <li>Respond to your inquiries and provide customer support</li>
          <li>Improve our website and services based on your feedback</li>
          <li>Communicate with you about updates or promotional offers</li>
        </ul>
      </section>

      <section className="privacy__section">
        <h2 className="privacy__subtitle">Cookies</h2>
        <p className="privacy__text">
          Our website uses cookies to collect information about how you use our
          site. Cookies are small data files that help us understand your
          preferences and improve your experience. You can control your cookie
          settings in your browser at any time.
        </p>
      </section>

      <section className="privacy__section">
        <h2 className="privacy__subtitle">Third-Party Services</h2>
        <p className="privacy__text">
          We may use third-party services, such as analytics tools or
          advertising platforms, that collect, monitor, and analyze data to
          improve our website and marketing efforts. These services may have
          access to your personal information only to perform tasks on our
          behalf and are obligated not to disclose or use it for any other
          purpose.
        </p>
      </section>

      <section className="privacy__section">
        <h2 className="privacy__subtitle">Data Security</h2>
        <p className="privacy__text">
          We take reasonable measures to protect your personal information from
          unauthorized access, disclosure, or alteration. However, no method of
          transmission over the Internet is 100% secure, and we cannot guarantee
          the absolute security of your data.
        </p>
      </section>

      <section className="privacy__section">
        <h2 className="privacy__subtitle">Your Rights</h2>
        <p className="privacy__text">
          You have the right to request access to the personal information we
          hold about you, request corrections or deletions, and withdraw your
          consent to data collection at any time. If you wish to exercise any of
          these rights, please contact us at fromolded@gmail.com.
        </p>
      </section>

      <section className="privacy__section">
        <h2 className="privacy__subtitle">Changes to This Policy</h2>
        <p className="privacy__text">
          We may update this Privacy & Policy from time to time. Any changes
          will be posted on this page, and we encourage you to review the policy
          regularly to stay informed about how we are protecting your
          information.
        </p>
      </section>

      <section className="privacy__section">
        <h2 className="privacy__subtitle">Contact Us</h2>
        <p className="privacy__text">
          If you have any questions about this Privacy & Policy, please contact
          us at:
        </p>
        <p className="privacy__text">
          Email: fromolded@gmail.com <br />
          Telegram:{" "}
          <a href="https://t.me/fromolded" className="privacy__link">
            @fromolded
          </a>
        </p>
      </section>
    </div>
  );
};

export default PrivacyPolicy;
