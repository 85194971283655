import React from "react";

const TermsAndConditions = () => {
  return (
    <div className="privacy">
      <h1 className="privacy__title">Terms & Conditions</h1>
      <section className="privacy__section">
        <h2 className="privacy__subtitle">Introduction</h2>
        <p className="privacy__text">
          Welcome to Fromolded. These terms and conditions outline the rules and
          regulations for the use of Fromolded Solutions' website and services.
          By accessing this website, we assume you accept these terms and
          conditions in full.
        </p>
      </section>

      <section className="privacy__section">
        <h2 className="privacy__subtitle">Our Services</h2>
        <p className="privacy__text">
          At Fromolded Solutions, we specialize in providing high-quality
          digital solutions, including landing pages, advertising pages, and web
          applications. Our services are designed to meet the unique business
          needs of our clients.
        </p>
        <ul className="privacy__list">
          <li>Landing Pages</li>
          <li>Advertising Pages</li>
          <li>Web Applications</li>
        </ul>
      </section>

      <section className="privacy__section">
        <h2 className="privacy__subtitle">User Responsibilities</h2>
        <p className="privacy__text">
          By using our services, you agree to provide accurate and up-to-date
          information, and to comply with all applicable laws and regulations.
          You are responsible for maintaining the confidentiality of your
          account information and ensuring that all activities under your
          account comply with these terms.
        </p>
      </section>

      <section className="privacy__section">
        <h2 className="privacy__subtitle">Intellectual Property</h2>
        <p className="privacy__text">
          All content provided by Fromolded Solutions, including design
          elements, logos, and text, is the intellectual property of Fromolded
          Solutions unless otherwise stated. You are not permitted to reuse,
          copy, or distribute any content without our express written consent.
        </p>
      </section>

      <section className="privacy__section">
        <h2 className="privacy__subtitle">Termination of Services</h2>
        <p className="privacy__text">
          Fromolded Solutions reserves the right to terminate or suspend access
          to our services at any time if we determine that there has been a
          breach of these terms. This includes unauthorized use of our
          intellectual property or violations of user responsibilities.
        </p>
      </section>

      <section className="privacy__section">
        <h2 className="privacy__subtitle">Changes to Terms</h2>
        <p className="privacy__text">
          Fromolded Solutions reserves the right to modify these terms and
          conditions at any time. Any changes will be posted on this page, and
          it is your responsibility to review them regularly.
        </p>
      </section>

      <section className="privacy__section">
        <h2 className="privacy__subtitle">Contact Us</h2>
        <p className="privacy__text">
          If you have any questions about these terms and conditions, please
          contact us at:
        </p>
        <p className="privacy__text">
          Email: fromolded@gmail.com <br />
          Telegram:{" "}
          <a href="https://t.me/fromolded" className="privacy__link">
            @fromolded
          </a>
        </p>
      </section>
    </div>
  );
};

export default TermsAndConditions;
