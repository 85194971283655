import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import WhoAreWe from "./components/Page1/WhoAreWe";
import WhyUs from "./components/Page1/WhyUs";
import WhatWeOffer from "./components/Page1/WhatWeOffer";
// import OurClients from "./components/Page1/OurClients";
import HeroBanner from "./components/Page1/HeroBanner";
import Portfolio from "./components/Portfolio/Portfolio";
import "./assets/scss/main.css";
// import Testimonials from "./components/Page1/Testimonials";
import Services from "./components/Services1/Services";
import CentralSection from "./components/Page1/CentralSection";
import Offers from "./components/Page1/Offers";
import ContactUsSection from "./components/Contact/Contact";
import TermsAndConditions from "./components/TermsPrivacy/Terms";
import PrivacyPolicy from "./components/TermsPrivacy/Privacy";

function App() {
  return (
    <Router>
      <div className="wrapper">
        <Header />
        <main className="main">
          <Routes>
            <Route
              path="/"
              element={
                <>
                  <HeroBanner />
                  <WhatWeOffer />
                  <Offers />
                  <WhoAreWe />
                  <CentralSection />
                  <WhyUs />
                  {/* <OurClients />
                  <Testimonials /> */}
                </>
              }
            />
            <Route path="/portfolio" element={<Portfolio />} />
            <Route path="/services" element={<Services />} />
            <Route path="/contact" element={<ContactUsSection />} />
            <Route path="/terms" element={<TermsAndConditions />} />
            <Route path="/privacy" element={<PrivacyPolicy />} />
          </Routes>
        </main>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
