import React, { useEffect } from "react";
import {
  FaRocket,
  FaCheckCircle,
  FaHandsHelping,
  // FaClock,
  FaShieldAlt,
} from "react-icons/fa";

const WhyUs = () => {
  useEffect(() => {
    let currentCardIndex = 0;
    const cards = document.querySelectorAll(".why-us__card");
    const intervalTime = 4000; // 4 секунды

    const cycleHighlight = () => {
      cards.forEach((card, index) => {
        card.classList.remove("highlight");
        if (index === currentCardIndex) {
          card.classList.add("highlight");
        }
      });

      // Увеличиваем индекс, чтобы подсветить следующую карточку
      currentCardIndex = (currentCardIndex + 1) % cards.length;
    };

    // Запуск автоматической подсветки
    const interval = setInterval(cycleHighlight, intervalTime);

    return () => clearInterval(interval); // Очищаем таймер при размонтировании компонента
  }, []);

  return (
    <section className="why-us" id="why-us">
      <div className="why-us__content">
        <h2 className="why-us__title">Why Choose Us?</h2>
        <div className="why-us__cards">
          <div className="why-us__card">
            <FaRocket className="why-us__icon" />
            <h3 className="why-us__card-title">Speed</h3>
            <p className="why-us__card-description">
              We deliver fast and efficient solutions, ensuring your projects
              are completed on time without compromising quality.
            </p>
          </div>
          <div className="why-us__card">
            <FaCheckCircle className="why-us__icon" />
            <h3 className="why-us__card-title">Quality</h3>
            <p className="why-us__card-description">
              Our commitment to quality guarantees that every landing page we
              create meets the highest standards.
            </p>
          </div>

          <div className="why-us__card">
            <FaShieldAlt className="why-us__icon" />
            <h3 className="why-us__card-title">Responsibility</h3>
            <p className="why-us__card-description">
              We take full responsibility for every project, ensuring all
              deliverables are on point and meet your expectations.
            </p>
          </div>
          <div className="why-us__card">
            <FaHandsHelping className="why-us__icon" />
            <h3 className="why-us__card-title">Support</h3>
            <p className="why-us__card-description">
              Our team is always ready to assist you with any queries or
              concerns, providing ongoing support throughout the project.
            </p>
          </div>
          {/* <div className="why-us__card">
            <FaClock className="why-us__icon" />
            <h3 className="why-us__card-title">Timeliness</h3>
            <p className="why-us__card-description">
              We value your time, which is why we adhere strictly to deadlines,
              ensuring timely delivery of your projects.
            </p>
          </div> */}
        </div>
      </div>
    </section>
  );
};

export default WhyUs;
