// HeroBanner.jsx
import React from "react";
import { useTypewriter, Cursor } from "react-simple-typewriter";
import { FaFacebook, FaGoogle } from "react-icons/fa";

const HeroBanner = () => {
  const [text] = useTypewriter({
    words: ["Developers.", "Designers.", "Creators."],
    loop: 0, // 0 для бесконечного цикла
    typeSpeed: 40,
    deleteSpeed: 30,
    delaySpeed: 1000,
  });

  return (
    <section className="hero-banner" id="hero-banner">
      <div className="hero-banner__content">
        <h1 className="hero-banner__title" data-text="Fromolded Solutions">
          Fromolded Solutions
        </h1>
        <div id="page_wrap">
          <div className="banner">
            <div className="typed_wrap">
              <h1>
                We Are {text}
                <Cursor />
              </h1>
            </div>
          </div>
        </div>
        {/* <p className="hero-banner__subtitle">
          We Create Memorable Digital Experiences
        </p> */}
      </div>
      <div className="hero-banner__platforms">
        <p>We Create Memorable Digital Experiences:</p>
        <div className="hero-banner__icons">
          <FaFacebook className="hero-banner__icon" aria-label="Facebook" />
          <FaGoogle className="hero-banner__icon" aria-label="Google" />
        </div>
      </div>
    </section>
  );
};

export default HeroBanner;
