import React from "react";

const WhoAreWe = () => {
  return (
    <section className="who-are-we" id="about">
      <div className="who-are-we__content">
        <h2 className="who-are-we__title">Who Are We?</h2>
        <div className="who-are-we__description">
          <p className="who-are-we__paragraph">
            <strong>Fromolded Solutions</strong> is a creative agency
            specializing in the creation of effective and attractive landing
            pages.
          </p>
          <p className="who-are-we__paragraph">
            Our mission is to help companies achieve their business goals
            through <em>innovative web design</em> and{" "}
            <em>marketing solutions</em>.
          </p>
          <p className="who-are-we__paragraph">
            We combine experience, professionalism, and a creative approach to
            create landing pages that <span className="highlight">attract</span>{" "}
            and <span className="highlight">retain</span> customers.
          </p>
          <p className="who-are-we__paragraph">
            Thanks to our deep understanding of market needs, we offer
            customized solutions that meet the specific needs of your business.
          </p>
        </div>
      </div>
    </section>
  );
};

export default WhoAreWe;
