import React, { useEffect } from "react";
import { FaTelegramPlane, FaEnvelope } from "react-icons/fa";
import { useLocation } from "react-router-dom";

const ContactUsSection = () => {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <section className="contact-us">
      <h2 className="contact-us__subtitle">Get in Touch</h2>
      <p className="contact-us__description">
        If you have any questions, feedback, or just want to get in touch, feel
        free to reach out to us via email or Telegram. We’re always happy to
        hear from you!
      </p>
      <div className="contact-us__details">
        <div className="contact-us__detail-item">
          <FaEnvelope className="contact-us__icon" />
          <p className="contact-us__detail-text">Email: fromolded@gmail.com</p>
        </div>
        <div className="contact-us__detail-item">
          <FaTelegramPlane className="contact-us__icon" />
          <p className="contact-us__detail-text">
            Telegram:{" "}
            <a href="https://t.me/fromolded" className="contact-us__link">
              @fromolded
            </a>
          </p>
        </div>
      </div>
    </section>
  );
};

export default ContactUsSection;
